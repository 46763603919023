import methods from '@/enums/methods';

import Atom from '../base/atom';

class AtomService extends Atom {
    getAllGroups() {
        return this.fetch({
            url: '/group',
        });
    }

    getDetailsById(id) {
        return this.fetch({
            url: `/group/${id}/details`,
        });
    }

    getAllUsers() {
        return this.fetch({
            url: '/user',
        });
    }

    sendMessage({ content, receiversIds }) {
        return this.fetch({
            url: '/message/text',
            method: methods.POST,
            data: {
                content,
                receiver_ids: receiversIds,
            },
        });
    }

    sendMediaMessage({
        content,
        receiverId,
        type,
        caption,
    }) {
        const formData = new FormData();
        formData.append('content', content);

        formData.append('receiver_id', receiverId);
        formData.append('type', type);
        formData.append('caption', caption);

        return this.fetch({
            method: methods.POST,
            url: '/message/media',
            data: formData,
        });
    }

    addMemberInGroup(
        groupId, usersIds,
    ) {
        return this.fetch({
            url: '/group/add',
            method: methods.POST,
            data: {
                group_id: groupId,
                user_ids: usersIds,
            },
        });
    }

    removeMemberInGroup(groupId, usersIds) {
        return this.fetch({
            url: '/group/remove',
            method: methods.DELETE,
            data: {
                group_id: groupId,
                user_ids: usersIds,
            },
        });
    }

    pormoteMemberInGroup(groupId, usersIds) {
        return this.fetch({
            url: '/group/promote',
            method: methods.PUT,
            data: {
                group_id: groupId,
                user_ids: usersIds,
            },
        });
    }

    demoteMemberInGroup(groupId, usersIds) {
        return this.fetch({
            url: '/group/demote',
            method: methods.PUT,
            data: {
                group_id: groupId,
                user_ids: usersIds,
            },
        });
    }

    createNewGroup(name) {
        return this.fetch({
            url: '/group',
            method: methods.POST,
            data: {
                name,
                user_ids: [],
            },
        });
    }
}

export default new AtomService();
