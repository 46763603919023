<template>
  <div>
    <b-button
      variant="primary"
      class="mb-1 ml-auto"
      @click="$refs['modal-add-members'].show()"
    >
      Adicionar Membro
    </b-button>

    <b-table
      class="text-center"
      :tbody-tr-class="rowClass"
      :fields="[
        { key: 'name', label: 'Nome' },
        { key: 'phone', label: 'Telefone'},
        { key: 'role', label: 'Cargo '},
        { key: 'action', label: 'Ação '}
      ]"
      :items="group.details.members"
    >
      <template #cell(role)="data">
        {{ data.item.role === 'regular' ? 'Comum' : 'Admin' }}
      </template>
      <template #cell(action)="data">
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreHorizontalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>

          <b-dropdown-item @click="remove(data.item)">

            <span>Remover do Grupo</span>
          </b-dropdown-item>

          <b-dropdown-item @click="changeRole(data.item)">

            <span> {{ data.item.role === 'regular' ? 'Promover Para Admin' : 'Retirar Admin' }} </span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
    <b-button
      variant="primary"
      class="mt-1"
      @click="update"
    >
      Salvar
    </b-button>

    <b-modal
      ref="modal-add-members"
      hide-footer
      size="lg"
      title="Adicionar Membro"
    >
      <b-form-group
        label="Usuário"
        label-for="user"
      >
        <b-form-select
          v-model="selectedAddUser"
          :options="options"
        />
      </b-form-group>

      <b-button
        variant="primary"
        @click="add"
      >
        Adicionar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
    BTable, BButton, BDropdown, BDropdownItem, BModal, BFormSelect, BFormGroup,
} from 'bootstrap-vue';

import AtomService from '@/service/atom';

export default {
    components: {
        BTable, BButton, BDropdown, BDropdownItem, BModal, BFormSelect, BFormGroup,
    },

    props: {
        group: {
            type: Object,
            required: true,
        },

        users: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            newMembers: [],
            removedMembers: [],
            promotedMembers: [],
            demotedMembers: [],
            selectedAddUser: null,
        };
    },

    computed: {
        options() {
            const options = this.users.filter(user => (!this.group.details.members.find(member => member.id === user.id) || (!this.newMembers.find(member => member.id === user.id)))).map(user => ({
                value: user,
                text: `${user.name} - ${user.phone}`,
            }));

            options.unshift({
                value: null,
                text: 'Selecione um usuário',
            });

            return options;
        },
    },

    methods: {
        add() {
            this.newMembers.push(this.selectedAddUser);
            this.group.details.members.push({
                ...this.selectedAddUser,
                role: 'regular',
            });
            this.selectedAddUser = null;
            this.$refs['modal-add-members'].hide();
        },

        remove(member) {
            const newMemberIndex = this.newMembers.findIndex(item => item.id === member.id);

            if (newMemberIndex >= 0) {
                this.newMembers.splice(newMemberIndex, 1);

                const index = this.group.details.members.findIndex(item => item.id === member.id);
                this.group.details.members.splice(index, 1);
            } else {
                this.removedMembers.push(member);
            }
        },

        async update() {
            const response = await this.confirmAnAction('Deseja realmente atualizar esse grupo?');
            if (!response) return;

            this.callLoading(true);

            if (this.newMembers.length) {
                await AtomService.addMemberInGroup(this.group.id, this.newMembers.map(item => item.id));
            }
            if (this.removedMembers.length) {
                await AtomService.removeMemberInGroup(this.group.id, this.removedMembers.map(item => item.id));
            }
            if (this.promotedMembers.length) {
                await AtomService.pormoteMemberInGroup(this.group.id, this.promotedMembers.map(item => item.id));
            }
            if (this.demotedMembers.length) {
                await AtomService.demoteMemberInGroup(this.group.id, this.demotedMembers.map(item => item.id));
            }

            this.modalSuccess('Grupos alterados com sucesso');

            this.callLoading(false);

            this.$emit('update');
        },

        changeRole(data) {
            const item = data;
            if (item.role === 'admin') {
                this.demotedMembers.push(data);
                item.role = 'regular';

                const newMemberIndex = this.promotedMembers.findIndex(promotedMember => promotedMember.id === data.id);

                if (newMemberIndex >= 0) {
                    this.promotedMember.splice(newMemberIndex, 1);
                }
            } else {
                item.role = 'admin';
                this.promotedMembers.push(data);

                const newMemberIndex = this.demotedMembers.findIndex(promotedMember => promotedMember.id === data.id);

                if (newMemberIndex >= 0) {
                    this.demotedMembers.splice(newMemberIndex, 1);
                }
            }
        },

        rowClass(item) {
            if (this.newMembers.find(newMember => item.id === newMember.id)) {
                return 'success-opacity';
            }

            if (this.removedMembers.find(newMember => item.id === newMember.id)) {
                return 'danger-opacity';
            }

            return '';
        },
    },
};
</script>

<style lang="scss">
    .success-opacity {
        background-color: rgba(#28c76f, .13)
    }

    .danger-opacity {
        background-color: rgba(#ea5455, .13)

    }
</style>
