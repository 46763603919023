<template>
  <div>
    <b-card title="Grupos">
      <b-button
        variant="primary"
        class="mb-1"
        @click="$refs['modal-create-group'].show()"
      >
        Criar Novo Grupo
      </b-button>
      <b-table
        class="text-center"
        :items="groups"
        :per-page="12"
        hover
        :page="page"
        :current-page="page"
        :fields="[
          { key: 'name', label: 'nome'},
          { key: 'amount', label: 'quantidade de membros'}
        ]"
        @row-clicked="getDetails"
      >
        <template #cell(amount)="data">
          {{ data.item.members.length }}
        </template>
      </b-table>
      <b-pagination
        v-model="page"
        :total-rows="groups.length"
        :per-page="12"
        align="center"
        size="sm"
        class="my-2"
      />
    </b-card>

    <b-modal
      ref="modal-update"
      hide-footer
      size="lg"
      :title="current.name"
    >
      <edit-group
        :users="users"
        :group="current"
        @update="() => {
          $refs['modal-update'].hide()
          fetchGroups()
        }"
      />
    </b-modal>

    <b-modal
      ref="modal-create-group"
      hide-footer
      size="lg"
      title="Criar Novo Grupo"
    >
      <b-form-group
        label="Nome"
        label-for="name"
      >
        <b-form-input v-model="newGroupName" />

        <b-button
          variant="primary"
          class="mt-1"
          @click="createNewGroup"
        >
          Criar
        </b-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
    BTable, BCard, BPagination, BModal, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue';

import EditGroup from './Components/Edit.vue';

import AtomService from '@/service/atom';

export default {
    components: {
        BTable, BCard, BPagination, BModal, EditGroup, BButton, BFormGroup, BFormInput,
    },
    data() {
        return {
            groups: [],
            page: 1,
            requests: {},
            current: {},
            users: [],
            newGroupName: '',
        };
    },
    async mounted() {
        this.callLoading(true);
        await this.fetchUsers();
        await this.fetchGroups();
        this.callLoading(false);
    },

    methods: {
        async fetchDetails(id) {
            const { data } = await AtomService.getDetailsById(id);
            this.requests[id] = data.data;
            return data.data;
        },

        async fetchGroups() {
            this.groups = (await AtomService.getAllGroups()).data.data.sort((a, b) => a.name.localeCompare(b.name));
        },

        async getDetails(data) {
            this.$refs['modal-update'].show();
            this.current = await this.fetchDetails(data.id);
        },

        async fetchUsers() {
            this.users = (await AtomService.getAllUsers()).data.data;
        },

        async createNewGroup() {
            this.callLoading(true);
            const { status } = await AtomService.createNewGroup(this.newGroupName);

            if (status === 201 || status === 200) {
                this.modalSuccess('Grupo criado com sucesso');
                this.$refs['modal-create-group'].hide();
            } else {
                this.modalError('Erro ao criar o grupo');
            }
            await this.fetchGroups();
            this.callLoading(false);
        },
    },
};
</script>
